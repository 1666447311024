<template>
    <SimpleTable formclass="Planning/form" endpoint="planning" :aditionalColumns="headers">
        <template>
            <h1>Planejamento de Loja</h1>
            <v-divider class="mb-4" />
        </template>
        <template v-slot:extra-column="item">
            <v-chip class="ma-0" outlined color="success" v-if="item.date"> {{ $moment.utc(item.date).format("DD/MM/YYYY") }} </v-chip>
        </template>
    </SimpleTable>
</template>

<script>
import SimpleTable from "@/components/core/SimpleTable";
export default {
    components: {
        SimpleTable,
    },
    data() {
        return {
            headers: [
                { text: this.$t("Data"), value: "extra-column", align: "right", sortable: false },
                { text: this.$t("Loja"), value: "store", align: "right", sortable: true },
            ],
        };
    },
};
</script>